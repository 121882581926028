<template>
  <v-container>
    <Xendit
      :dark="dark"
      :drawer="drawer"
      :windowWidth="windowWidth"
      amount="1"
    />
    <br />
    <Footer
      id="footer"
      :isMobileDevice="isMobileDevice"
      :dark="dark"
      :drawer="drawer"
      :windowWidth="windowWidth"
    >
    </Footer>
  </v-container>
</template>

<script>
import Footer from "./Footer.vue";
import Xendit from "./comp/Payment/Xendit.vue";
export default {
  name: "Payment",
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
    };
  },
  mounted() {},
  watch: {},
  components: {
    Footer,
    Xendit,
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.montserrat {
  font-family: "Montserrat";
}
</style>
