<template>
  <div>
    <bike-standart-component
      :model="model"
      :dark="dark"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
      :windowHeight="windowHeight"
      :drawer="drawer"
      v-if="bike.typeProduct == 'standart'"
    />
    <bike-edition-component
      :model="model"
      :dark="dark"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
      :windowHeight="windowHeight"
      :drawer="drawer"
      v-if="bike.typeProduct == 'edition'"
    />

    <BikeNewModelComponent
      :model="model"
      :dark="dark"
      :isMobileDevice="isMobileDevice"
      :windowWidth="windowWidth"
      :windowHeight="windowHeight"
      :drawer="drawer"
      v-if="bike.typeProduct == 'new_model'"
    />

    <!-- Dialog alert send data -->
    <v-dialog v-model="alertMassageSend" max-width="290">
      <v-card class="text-center p-4">
        <v-card-text class="text-h5 text-center">
          <v-icon size="x-large" style="color: greenyellow; font-size: 3rem"
            >mdi-check-circle-outline</v-icon
          >
          <h1 class="mt-2">
            {{
              getLanguage == "en"
                ? "Send Data Form Success!"
                : "Data Form Berhasil Terkirim!"
            }}
          </h1>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BikeStandartComponent from "./BikeStandartComponent.vue";
import BikeEditionComponent from "./BikeEditionComponent.vue";
import BikeNewModelComponent from "./BikeNewModelComponent.vue";

export default {
  name: "BikeComponent",
  props: {
    model: String,
    dark: Boolean,
    isMobileDevice: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
  },
  created() {
    this.slug = this.$route.params.slug;
    this.$store.dispatch("set_vehicle_show_store", this.slug);
    this.bike = this.$store.getters.vehicle_show_store;
    if (!this.bike) {
      this.$router.push("/");
    }
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data: () => {
    return {
      bike: null,
      alertMassageSend: false,
    };
  },
  components: {
    BikeStandartComponent,
    BikeEditionComponent,
    BikeNewModelComponent,
  },
  methods: {
    openDialogAlert() {
      this.alertMassageSend = true;
    },
    closeDialogAlert() {
      this.alertMassageSend = false;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.montserrat {
  font-family: "Montserrat" !important;
}

/* Desqy Add this css */

.work-sans {
  font-family: "Work Sans";
}

/* Navbar */
.v-app-bar--fixed {
  color: black !important;
}

.v-app-bar--fixed .v-btn {
  color: black !important;
}

.v-app-bar--fixed .v-icon {
  color: black !important;
}

/* END Navbar */

/* FAQ */

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px solid #ccc !important;
}

.panel-title {
  font-size: 18px;
  line-height: 26px;
  color: #262626;
  font-weight: 700;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  font-size: 16px;
  padding: 0px 14px;
  line-height: 26px;
  font-weight: 400;
  color: #535456;
  height: 0;
  overflow: hidden;
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  /* margin-left: 1120px; */
  right: 40px;
  margin-top: 4px;
  z-index: 1;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

.catalog-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 3rem;
}

.cities-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

@media (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .plus {
    margin-left: 700px;
  }

  .panel-title {
    margin-left: -45px;
  }
}

@media (max-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .plus {
    z-index: 1;
    margin-left: 350px;
  }

  .panel-title {
    margin-left: -35px;
  }

  .text-responsive-m {
    font-size: 4.2vw !important;
  }
}

.bike-logo {
  margin-top: 3rem;
}

.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.banner-subscribe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  padding: 5rem;
}

.banner-subscribe-container-mobile {
  padding-top: 25rem;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-bottom: 5vh;
}

.bike-color {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.bike-color-title {
  font-weight: 600;
  color: white;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.banner-footer {
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  margin: 0 !important;
}

.banner-footer-mobile {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
  margin: 0 !important;
}

.banner-footer-info {
  /* display: flex;
  flex-direction: column; */

  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  color: white;
  border-right: 1px solid white;
  padding-right: 2rem;
}

.banner-footer-info-mobile {
  text-align: center;
  width: 100%;
  color: white;
}

.banner-footer-info-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.banner-footer-info-title-mobile {
  font-size: 15px;
  font-weight: 700;
}

.banner-footer-info-subtitle {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08em;
}

.banner-footer-info-subtitle-mobile {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.banner-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding-left: 3rem;
}

.test-ride-button {
  color: #6b4693 !important;
}

.form-city {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.input-label {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #858688;
}

.no-location-submit-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #262626;
}

.no-location-submit-description {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #535456;
  text-align: justify;
  text-align-last: center;
}

/* @media (min-width:320px)  {     
    .plus {
      z-index: 1;
      margin-left: 250px;
    }
    .panel-title {
      margin-left: -35px;
    }
  } */

/* END FAQ */

/* END Desqy css */
.sudo-line {
  position: relative;
}
.sudo-line::after {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  width: 42%;
  height: 2px;
  background: #c1c2c4;
}
.sudo-line::before {
  content: "";
  position: absolute;
  right: 0;
  top: 40%;
  width: 42%;
  height: 2px;
  background: #c1c2c4;
}

.custom-table-hover
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: none;
}

.custom-table-hover .v-tabs-slider-wrapper .v-tabs-slider {
  display: none;
}

.btn-toggle-collaps-product::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2.3px;
  right: -25px;
  top: 23px;
  background: #262626;
}

.btn-toggle-collaps-product::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2px;
  right: -25px;
  top: 23px;
  background: #262626;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps-product::before {
  transform: rotate(0deg);
}

.btn-toggle-collaps-product-tab-2::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2.3px;
  right: -25px;
  top: 23px;
  background: #fff;
}

.btn-toggle-collaps-product-tab-2::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 16px;
  height: 2px;
  right: -25px;
  top: 23px;
  background: #fff;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps-product-tab-2::before {
  transform: rotate(0deg);
}
</style>
