<template>
  <v-sheet tile>
    <!-- <v-container fill-height fluid v-if="loading" style="height: 100vh;">
      <v-row align="center" justify="center">
        <img
          src="../../../../public/img/launch/RimbaLogo.svg"
          width="350"
          height="90"
        />
      </v-row>
    </v-container> -->
    <div class="pa-0 ma-0">
      <!-- <v-app-bar color="#262626" fixed app elevation="1">
        <v-img
          style="
            position: absolute;
            z-index: 99;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          @click="routerGo('/')"
          alt="Charged Factory | Electronic Vehicle Indonesia"
          src="../../../../public/img/logo/Charged.svg"
          min-width="125px"
          min-height="32px"
          max-width="125.05px"
          max-height="32px"
        >
        </v-img>
        <v-menu
          offset-y
          style="
            position: absolute;
            width: 100%;
            margin-top: -150px;
            z-index: 99;
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              color="white"
              style="
                text-transform: none !important;
                z-index: 99;
                position: absolute;
                right: 16px;
              "
            >
              {{ getLanguage === "id" ? "ID" : "EN"
              }}<v-icon color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changeLanguage('en')">
              <v-list-item-title>EN (English)</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLanguage('id')">
              <v-list-item-title>ID (Indonesia)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar> -->
      <section :id="windowWidth <= 560 ? 'rimba-hero-mobile' : 'rimba-hero'"
        class="chaged-section d-flex align-end justify-center hidden">
        <div class="hero-title text-over-image-white mb-6 pb-0">
          <v-card-title :class="
              'charged-title-2 pb-5 px-md-4 px-5 ' +
              (windowWidth <= 560 ? 'justify-left' : 'justify-center')
            " v-text="lang[getLanguage].RIMBA_TITLE"></v-card-title>
          <div class="px-md-16">
            <v-card-text :class="
                'justify-center mb-12 pb-0 px-md-16 px-5 charged-body-h2 text-over-image-white ' +
                (windowWidth <= 560 ? 'txt-left' : 'text-center')
              " v-html="lang[getLanguage].RIMBA_DESCRIPTION">
            </v-card-text>
          </div>
        </div>
      </section>
      <!--Environment 2 Images-->
      <section id="rimba-environment" v-if="windowWidth > 560" style="overflow-x: clip"
        class="d-flex align-end justify-center pt-16 px-16 pb-0 hidden">
        <v-row class="mt-14 mx-16 px-0">
          <v-col cols="12" md="6" class="mx-auto px-auto">
            <v-img src="../../../../public/img/rimba/RimbaVision-1.png" :max-width="windowWidth"
              class="mx-auto img-section"></v-img>
          </v-col>
          <v-col cols="12" md="6" class="mx-auto px-auto">
            <v-img src="../../../../public/img/rimba/RimbaVision-2.png" :max-width="windowWidth"
              class="mx-auto img-section"></v-img>
          </v-col>
        </v-row>
      </section>
      <!--Environment 2 Images Mobile-->
      <section id="rimba-environment-mobile" v-if="windowWidth <= 560" class="hidden" style="overflow-x: hidden">
        <div style="transform: translateX(0%)" :style="{
            transform: `translateX(-${(1 - value) * (560 - windowWidth)}px)`,
          }" class="d-flex pt-10 mt-10 pb-2">
          <picture>
            <img alt="Profauna Rimba Campaign 1" style="max-width: 280px; max-height: 240px" class="pl-5 pr-2"
              srcset="../../../../public/img/rimba/RimbaVisionMobile-1.png" /></picture>
          <picture>
            <img alt="Profauna Rimba Campaign 2" style="max-width: 280px; max-height: 240px" class="pl-2 pr-5"
              srcset="../../../../public/img/rimba/RimbaVisionMobile-2.png" /></picture>
        </div>
      </section>
      <!--Vision-->
      <section id="rimba-vision" class="align-end justify-center pt-md-16 pt-8 px-md-16 pb-14 mb-md-16 mb-4 hidden">
        <v-row class="mx-md-3 mx-auto">
          <v-col cols="12" md="8" class="px-5 py-md-3 pb-0">
            <v-card-title style="word-break: break-word" class="charged-environment-title px-0"
              v-text="lang[getLanguage].RIMBA_ENVIRONMENT_TITLE">
            </v-card-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4" class="px-5 d-flex" justify-content:
            :style="windowWidth <= 768 ? '' : 'justify-content: flex-end;'">
            <span class="charged-collab align-self-center">
              {{ lang[getLanguage].RIMBA_ENVIRONMENT_COLLAB }}
            </span>
            <v-img class="align-self-center ml-2" width="88px" max-width="88px" max-height="40px" height="40.75px"
              src="../../../../public/img/rimba/Profauna.png">
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mx-md-3 mx-auto">
          <v-col cols="12" md="8" class="px-5 py-md-3 pt-0">
            <v-card-text class="charged-environment-text pb-10 px-0" v-html="lang[getLanguage].RIMBA_ENVIRONMENT_TEXT">
            </v-card-text>
            <v-btn :ripple="false" class="charged-link-button pa-0 no-background-hover text-primary" text
              @click="goTo('https://www.profauna.net/id')">
              {{ lang[getLanguage].RIMBA_ENVIRONMENT_BUTTON }}
              <v-icon right class="text-primary">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="0" md="2"></v-col>
        </v-row>
      </section>
      <!--Banner-->
      <section id="rimba-banner" class="d-flex align-end justify-center mx-0 px-0 hidden">
        <v-img width="100%" min-height="400px" class="px-0 mx-0" :src="
            windowWidth <= 560
              ? require('../../../../public/img/rimba/BannerRimbaMobile.jpg')
              : require('../../../../public/img/rimba/BannerRimba.jpg')
          "></v-img>
      </section>
      <!--Mission-->
      <section id="rimba-mission" class="px-md-16 pt-10 hidden" style="background: #262626">
        <v-row class="mx-md-3 py-15 mx-auto">
          <!-- <v-col
          cols="12"
          md="6"
          class="pa-0 d-flex flex-column flex-grow align-self-center align-start"
        >
          <v-card-title
            class="d-flex pb-8 montserrat text-over-image-white"
            v-text="
              lang[getLanguage].ABOUT_CAROUSEL_CONTENT[carousel_count].title
            "
          >
          </v-card-title>
          <v-card-text
            class=" d-flex montserrat text-over-image-white mb-auto"
            align-self-end
            v-text="
              lang[getLanguage].ABOUT_CAROUSEL_CONTENT[carousel_count].content
            "
          >
          </v-card-text>
          <v-card-actions class=" d-flex mt-auto">
            <v-btn
              icon
              tile
              :ripple="false"
              @click="
                carousel_count === 0
                  ? (carousel_count =
                      lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1)
                  : (carousel_count -= 1)
              "
              class="text-over-image-white ma-0 pa-0"
              justify-start
            >
              <v-icon class="pa-0 ma-0" large color="white">
                mdi-arrow-left-thin
              </v-icon>
            </v-btn>
            <v-btn
              icon
              tile
              :ripple="false"
              @click="
                carousel_count ===
                lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1
                  ? (carousel_count = 0)
                  : (carousel_count += 1)
              "
              class="text-over-image-white ma-0 pa-0"
              justify-start
            >
              <v-icon class="pa-0 ma-0" large color="white">
                mdi-arrow-right-thin
              </v-icon>
            </v-btn>
            <span class="text-over-image-white">
              0{{ carousel_count + 1 }} / 0{{
                lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length
              }}
            </span>
          </v-card-actions>
        </v-col> -->
          <v-col md="6" cols="12" class="d-flex align-start flex-column pa-5 pa-md-4">
            <v-card-title style="word-break: break-word" class="pa-0 charged-smart-title text-over-image-white pb-8"
              v-text="lang[getLanguage].RIMBA_CAROUSEL_TITLE">
            </v-card-title>

            <v-card-text class="charged-smart-text text-over-image-white mb-auto pa-0"
              v-html="lang[getLanguage].RIMBA_CAROUSEL_TEXT">
            </v-card-text>

            <div class="pa-0 mt-auto" v-if="windowWidth > 768">
              <v-card-actions class="pa-0 d-flex mt-auto">
                <span class="text-over-image-white mr-3 charged-carousel">
                  0{{ carousel_count + 1 }} <span class="px-3">/</span>
                  <span style="color: #858688" class="charged-carousel">
                    0{{ lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length }}
                  </span>
                </span>
                <v-btn icon tile :ripple="false" :disabled="carousel_count === 0" @click="
                    carousel_count === 0
                      ? (carousel_count =
                          lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1)
                      : (carousel_count -= 1)
                  " class="text-over-image-white ma-0 pa-0 no-background-hover mx-2" justify-start>
                  <v-icon class="pa-0 ma-0" large color="white">
                    mdi-arrow-left-thin
                  </v-icon>
                </v-btn>
                <v-btn icon tile :ripple="false" :disabled="
                    carousel_count ===
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1
                  " @click="
                    carousel_count ===
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1
                      ? (carousel_count = 0)
                      : (carousel_count += 1)
                  " class="text-over-image-white ma-0 pa-0 no-background-hover mx-2">
                  <v-icon class="pa-0 ma-0" large color="white">
                    mdi-arrow-right-thin
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="pa-0">
            <v-img v-if="windowWidth > 768" :src="
                require(`../../../../public/img/rimba/SDMT_Rimba-${
                  carousel_count + 1
                }.jpg`)
              " class="ma-4"></v-img>
            <v-carousel v-else cycle interval="2000" :show-arrows="false" v-model="carousel_count" class="pa-5 pa-md-4"
              height="auto" hide-delimiter-background>
              <v-carousel-item v-for="(item, i) in itemSrcs" :key="i" :src="item.src">
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <!-- <v-card fullscreen tile color="#262626" v-if="windowWidth <= 768">
        <v-col class="pa-lg-0 ">
          <div class="">
            <div class="ma-0 pa-0 transition-fast-in-fast-out">
              <v-card-title
                style="word-break: break-word"
                class="text-h3 montserrat font-weight-bold text-over-image-white pa-2 mb-10"
                >{{ lang[getLanguage].ABOUT_CAROUSEL_TITLE }}
              </v-card-title>
              <v-img
                :src="visionImg1Src"
                class="ma-2 py-6"
                max-width="90%"
                fullscreen
              ></v-img>

              <v-card-text class="montserrat pa-2 mt-10" align-self-end>
                <h4
                  class="my-2 text-over-image-white"
                  v-text="
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT[carousel_count]
                      .title
                  "
                ></h4>
                <p
                  class="my-2 text-over-image-white"
                  v-text="
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT[carousel_count]
                      .content
                  "
                ></p>
              </v-card-text>
              <v-card-actions class="pa-2 ma-0">
                <v-btn
                  icon
                  tile
                  :ripple="false"
                  @click="
                    carousel_count === 0
                      ? (carousel_count =
                          lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1)
                      : (carousel_count -= 1)
                  "
                  class="text-over-image-white ma-0 pa-0"
                  justify-start
                >
                  <v-icon class="pa-0 ma-0" large color="white">
                    mdi-arrow-left-thin
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  tile
                  :ripple="false"
                  @click="
                    carousel_count ===
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length - 1
                      ? (carousel_count = 0)
                      : (carousel_count += 1)
                  "
                  class="text-over-image-white ma-0 pa-0"
                  justify-start
                >
                  <v-icon class="pa-0 ma-0" large color="white">
                    mdi-arrow-right-thin
                  </v-icon>
                </v-btn>
                <span class="text-over-image-white">
                  0{{ carousel_count + 1 }} / 0{{
                    lang[getLanguage].ABOUT_CAROUSEL_CONTENT.length
                  }}
                </span>
              </v-card-actions>
            </div>
          </div>
        </v-col>
      </v-card> -->
      </section>
      <!--Partner-->
      <section v-if="windowWidth > 1080" id="rimba-partner" class="chaged-section d-flex align-center px-md-16 hidden">
        <v-row class="hero-title mb-6 pb-0 mx-md-3 mx-auto">
          <v-col cols="7">
            <v-card-title class="charged-vmoto-title pb-6">
              {{ lang[getLanguage].RIMBA_PARTNER_TITLE }}
              <v-img class="ml-2" style="z-index: 99" src="../../../../public/img/rimba/VmotoBlack.png"
                max-width="195.75px" max-height="40px" min-width="195.75px" min-height="40px"></v-img>
            </v-card-title>
            <v-card-text class="mb-12 pb-0 charged-vmoto-text pr-16"
              v-html="lang[getLanguage].RIMBA_PARTNER_DESCRIPTION">
            </v-card-text>
            <v-btn class="pa-0 no-background-hover charged-link-button text-primary" style="margin-left: 14px"
              :ripple="false" @click="goTo('http://www.vmoto.com/')" text>
              {{ lang[getLanguage].RIMBA_PARTNER_BUTTON }}
              <v-icon right class="text-primary">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </section>
      <!--Partner-->
      <section class="d-flex align-end justify-center mx-0 px-0 hidden" v-if="windowWidth <= 1080">
        <v-img min-width="360px" min-height="400px" class="px-0 mx-0"
          src="../../../../public/img/rimba/Vmoto_Mobile.jpg"></v-img>
      </section>
      <section id="rimba-partner-mobile" v-if="windowWidth <= 1080" class="hidden">
        <v-row class="mx-auto">
          <v-col cols="12">
            <div class="hero-title mb-16 pb-8 mx-md-3 mx-auto">
              <v-card-title class="charged-title-2 pt-10 mt-10 pb-4 px-2"
                v-text="lang[getLanguage].RIMBA_PARTNER_TITLE">
              </v-card-title>
              <v-img class="ml-2" src="../../../../public/img/rimba/VmotoBlack.png" max-width="200px"></v-img>
              <v-card-text class="mb-16 pb-0 charged-body-h2 px-2" v-html="lang[getLanguage].RIMBA_PARTNER_DESCRIPTION">
              </v-card-text>

              <v-btn class="pa-0 no-background-hover charged-link-button text-primary" style="margin-left: 10px"
                :ripple="false" @click="goTo('http://www.vmoto.com/')" text>
                {{ lang[getLanguage].RIMBA_PARTNER_BUTTON }}
                <v-icon right class="text-primary">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </section>
      <!--Partner-->

      <section id="rimba-contact" style="background: #495142" class="hidden">
        <v-row justify="space-around" class="pt-6 pt-md-4 flex-column flex-md-row mx-0 px-5 mx-md-16 px-md-0"
          align="center">
          <v-col cols="12" md="10" class="px-0 px-md-4 pb-10 mt-md-9">
            <v-card-title :class="
                'px-0 text-over-image-white py-0 ' +
                (windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title')
              " style="word-break: break-word" v-html="lang[getLanguage].PL_CONTACT_TITLE"></v-card-title>
          </v-col>
          <v-col cols="12" md="2" class="px-0 py-auto d-flex" style="align-self: center">
            <v-btn :width="windowWidth <= 768 ? '100%' : 'fit-content'" color="white" :class="
                'charged-button-text no-uppercase py-md-3 px-md-8 ' +
                (windowWidth <= 768
                  ? 'charged-button'
                  : 'charged-button-mobile')
              " x-large @click="startChat()" style="color: #495142" elevation="2">
              {{ lang[getLanguage].PL_CONTACT_BUTTON }}
              <v-icon right color="#495142"> mdi-whatsapp </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="px-0 px-md-4 pt-md-0 mb-16 pb-9">
            <p v-html="
                windowWidth < 768
                  ? lang[getLanguage].PL_CONTACT_TEXT_MOBILE
                  : lang[getLanguage].PL_CONTACT_TEXT
              " class="charged-body-h2 text-over-image-white"></p>
          </v-col>
        </v-row>
      </section>

      <v-footer class="pa-0">
        <Footer id="footer" :isMobileDevice="isMobileDevice" :dark="dark" :drawer="drawer" :windowWidth="windowWidth">
        </Footer>
      </v-footer>
    </div>
  </v-sheet>
</template>

<script>
  import store from "@/store/index";
  import Footer from "../FooterPressLaunch";
  import {
    ref
  } from "vue";
  export default {
    name: "pressLaunch",
    metaInfo() {
      return {
        title: this.metaTitle,
        meta: [{
            name: "description",
            content: this.metaDesc
          },
          {
            property: "og:title",
            content: this.metaTitle
          },
          {
            property: "og:image",
            content: this.metaImg
          },
          {
            property: "og:url",
            content: this.metaUrl
          },
          {
            property: "og:site_name",
            content: "Charged Asia"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            name: "robots",
            content: "index,follow"
          },
        ],
      };
    },
    props: {
      dark: Boolean,
      windowWidth: Number,
      drawer: Boolean,
      isMobileDevice: Boolean,
    },
    computed: {
      getLanguage() {
        return this.$store.state.user.language;
      },
    },
    data() {
      return {
        lang: this.$store.state.lang,
        loading: true,
        //meta
        metaTitle: "Charged Asia - Ride for Good!",
        metaDesc: "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
        metaImg: "https://firebasestorage.googleapis.com/v0/b/charged-app.appspot.com/o/img%2FCharged%20Factory%20Thumbnail.png?alt=media&token=cc8bf9be-e653-49f2-b520-98f0ce484930",
        metaUrl: "https://charged.asia",
        carousel_count: 0,
        currentSection: ref(""),
        value: 1,
        itemSrcs: [{
            src: require("../../../../public/img/rimba/SDMT_Rimba-1_Mobile.jpg"),
          },
          {
            src: require("../../../../public/img/rimba/SDMT_Rimba-2_Mobile.jpg"),
          },
          {
            src: require("../../../../public/img/rimba/SDMT_Rimba-3_Mobile.jpg"),
          },
        ],
      };
    },
    components: {
      Footer,
    },
    created() {
      this.changeShowNavigation();
      window.addEventListener("scroll", this.handleScroll);
      // console.log(this.currentSection);
      // setTimeout(() => {
      //   this.loading = false;
      // }, 800);
    },
    // mounted() {
    //   setTimeout(() => {
    //     console.log("Mounted");
    //     const observer = new IntersectionObserver((entries) => {
    //       entries.forEach((entry) => {
    //         console.log(entry);
    //         if (entry.isIntersecting) {
    //           entry.target.classList.add("show-transitions");
    //         } else {
    //           entry.target.classList.remove("show-transitions");
    //         }
    //       });
    //     });
    //     document.querySelectorAll(".hidden").forEach((section) => {
    //       console.log(section);
    //       observer.observe(section);
    //     });
    //   }, 1300);
    // },
    methods: {
      handleScroll() {
        if (window.scrollY < 520) {
          this.value = 1;
        }
        if (window.scrollY > 620) {
          this.value = 0;
        }
        if (window.scrollY >= 520 && window.scrollY <= 620) {
          // console.log("start");
          // console.log(window.scrollY);
          // let calculation = (window.scrollY - 780) / (400 - 780);
          // console.log(calculation);
          // let j = -(1 - calculation) * 43;
          // console.log(j);
          // console.log("end");
          this.value = (window.scrollY - 620) / (520 - 620);
        }
      },
      // changeShowNavigation() {
      //   store.commit("HideNav");
      // },
      changeLanguage(lang) {
        store.commit("SetLanguage", {
          language: (this.getLanguage = lang),
        });
      },
      startChat() {
        var link = "https://api.whatsapp.com/send?phone=6281237686970&text=";
        if (this.getLanguage === "en") {
          link += "Hello%20Charged";
        }
        if (this.getLanguage === "id") {
          link += "Halo%20Charged";
        }
        this.goTo(link);
      },
      goTo(link) {
        window.open(link, "_blank");
      },
      routerGo(route) {
        this.$router.push(route);
      },
    },
  };
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

  #rimba-hero {
    /* The image used */
    background-image: linear-gradient(180deg,
        rgba(46, 43, 18, 0) 65%,
        rgba(46, 43, 18, 0.6) 100%),
      linear-gradient(180deg,
        rgba(77, 72, 25, 0) 33.7%,
        rgba(46, 43, 18, 0.3) 100%),
      url("../../../../public/img/rimba/HeroRimba.jpg");

    /* Full height */
    height: 95vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #rimba-hero-mobile {
    /* The image used */
    background-image: linear-gradient(180deg,
        rgba(46, 43, 18, 0) 65%,
        rgba(46, 43, 18, 0.6) 100%),
      linear-gradient(180deg,
        rgba(77, 72, 25, 0) 33.7%,
        rgba(46, 43, 18, 0.3) 100%),
      url("../../../../public/img/rimba/HeroRimbaMobile.jpg");

    /* Full height */
    height: 95vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #rimba-partner {
    /* The image used */
    background-image: linear-gradient(90deg,
        #ffffff 32.8%,
        rgba(255, 255, 255, 0) 79.72%),
      linear-gradient(88.1deg, #ffffff 30%, rgba(255, 255, 255, 0) 80%),
      url("../../../../public/img/rimba/Vmoto.jpg");

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* #rimba-banner {
  width: 1440px;
  height: 740px;

  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../../../../public/img/rimba/BannerRimba.png"), #d9d9d9;
} */

  .section-title {
    justify-content: space-evenly;
    margin-top: 70%;
  }

  .bottom-title {
    justify-content: center;
    place-items: flex-end;
  }

  .charged-section {
    min-height: 100vh;
  }

  .text-over-image-white {
    color: #ffffff !important;
  }

  .margin-x-340 {
    margin-left: 340px;
    margin-right: 340px;
  }

  .charged-title {
    font-family: "Montserrat" !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 45px !important;
    letter-spacing: -0.03em !important;
    text-align: left !important;
  }

  .charged-title-2 {
    font-family: "Montserrat" !important;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 35px !important;
    letter-spacing: -0.03em !important;
    text-align: left !important;
    word-break: break-word !important;
  }

  .charged-title-mobile {
    font-family: "Montserrat" !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    letter-spacing: -0.025em !important;
    text-align: left !important;
  }

  .charged-body-h1 {
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #353638;
  }

  .charged-body-h2 {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #353638;
  }

  .charged-p {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .charged-button-text {
    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .charged-button {
    border-radius: 8px;
    padding: 12px 24px 12px 32px;
  }

  .charged-button-mobile {
    border-radius: 8px;
    padding: 10px 24px 10px 24px;
  }

  .no-uppercase {
    text-transform: unset !important;
  }

  .theme--light.v-sheet--outlined {
    border: none;
  }

  .v-tab,
  .v-tab:hover {
    background: none !important;
  }

  .theme--dark.v-btn:hover::before {
    opacity: 1;
  }

  .plyr--init-hide-controls .plyr__controls {
    display: none;
  }

  .button-hide-me {
    display: none;
  }

  .no-background-hover::before {
    background-color: transparent !important;
  }

  /* .hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show-transitions {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
} */

  .text-primary {
    color: #6b4693 !important;
  }

  .theme--light.v-btn.v-btn--disabled .v-icon,
  .theme--light.v-btn.v-btn--disabled .v-btn__loading {
    color: #858688 !important;
  }

  /* @media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
} */
  .v-card--link:focus::before {
    opacity: 0;
  }

  .charged-collab {
    /* Body H4/Regular */

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* Charged/Grey/400 */

    color: #858688;
  }

  .charged-link-button {
    /* Body H3/Bold */

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: center;
    letter-spacing: -0.02em;

    /* Charged/Purple/300 */

    color: #6b4693;
  }

  .charged-vmoto-title {
    /* Subtitle H1/Bold */

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: -0.025em;

    /* Charged/Grey/100 */

    color: #262626;
  }

  .charged-vmoto-text {
    /* Body H3/Regular */

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* Charged/Grey/200 */

    color: #353638;
  }

  .charged-smart-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: -0.025em;

    /* Charged/Grey/900 */

    color: #ffffff;
  }

  .charged-smart-text {
    /* Body H3/Regular */

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* Charged/Grey/900 */

    color: #ffffff;
  }

  .charged-environment-title {
    /* Subtitle H1/Bold */

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: -0.025em;

    /* Charged/Grey/100 */

    color: #262626;
  }

  .charged-environment-text {
    /* Body H3/Regular */

    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    /* or 138% */

    align-items: center;
    letter-spacing: -0.02em;

    /* Charged/Grey/200 */

    color: #353638;
  }

  .charged-carousel {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */

    align-items: center;
    letter-spacing: -0.02em;
  }

  .v-carousel__controls {
    position: initial !important;
  }
</style>