<template>
  <div>
    <!-- ################################# Start View Mobile ################################ -->
    <div class="mt-5" v-if="$vuetify.breakpoint.mobile">
      <div class="flex items-center gap-3 px-4 justify-start">
        <v-btn icon color="green" @click="$parent.selectedMenu = null">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="font-work-sans text-lg font-semibold text-charged-grey-100"
        >
          {{
            getLanguage == "en" ? "History Subscription" : "Riwayat Langganan"
          }}
        </span>
      </div>
      <div class="px-3 mt-4">
        <!-- <v-card outlined class="rounded-lg">
          <v-card-text>
            <div class="flex justify-between">
              <div class="text-charged-grey-400">
                <span class="font-work-sans text-xs block">Order Submit</span>
                <span class="font-work-sans text-xs block">24/07/2023</span>
              </div>
              <div>
                <div class="bg-gray-100 px-1 rounded-lg">
                  <span
                    class="text-gray-400 inline-block text-xs font-work-sans whitespace-nowrap"
                  >
                    Not Approved
                  </span>
                </div>
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div class="flex items-center gap-5">
              <div>
                <v-img
                  src="http://127.0.0.1:8080/img/shared/bikes/anoa/bike-anoa.webp"
                  width="150"
                ></v-img>
              </div>
              <div>
                <span
                  class="font-work-sans font-semibold text-charged-grey-100 block"
                >
                  RIMAU
                </span>
                <span class="font-work-sans text-charged-grey-100 block"
                  >White</span
                >
              </div>
            </div>
          </v-card-text>
        </v-card> -->

        <v-img
          class="mx-auto mt-10"
          max-width="80"
          src="/empty-folder.png"
        ></v-img>
      </div>
    </div>
    <!-- ################################# End View Mobile ################################ -->

    <!-- ################################# Start View Desktop ################################ -->
    <div class="mt-10" v-if="$vuetify.breakpoint.lg">
      <span
        class="font-work-sans text-xl font-semibold text-charged-grey-100 mb-2 inline-block"
      >
        {{ getLanguage == "en" ? "History Subscription" : "Riwayat Langganan" }}
      </span>
      <p class="font-work-sans text-base text-charged-grey-400">
        {{
          getLanguage == "en"
            ? "These are your subscriptions that have ended or not approved."
            : "Ini adalah langganan Anda yang telah berakhir atau tidak disetujui."
        }}
      </p>

      <!-- <v-card outlined class="p-5 rounded-lg">
        <div class="flex">
          <div>
            <v-img
              src="http://127.0.0.1:8080/img/shared/bikes/anoa/bike-anoa.webp"
              width="150"
            ></v-img>
          </div>
          <div class="flex justify-between">
            <div class="font-work-sans text-sm text-charged-grey-400">
              <p>Not Approved on 24/07/2021 - SI/24681</p>
            </div>
            <div>
              <div class="bg-gray-100 px-1 rounded-lg">
                <span class="text-gray-400 inline-block text-sm font-work-sans">
                  Not Approved
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card> -->

      <v-img
        class="mx-auto mt-10"
        max-width="80"
        src="/empty-folder.png"
      ></v-img>
    </div>
    <!-- ################################# End View Desktop ################################ -->
  </div>
</template>
<script>
export default {
  name: "myaccount-history",
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      console.log("Acc History");
    },
  },
};
</script>